<script lang="ts" setup>
import axios from "axios";
import { useCookie } from "#app";
import { useUniversalPixel } from "~/composables/universalPixel";

useUniversalPixel().init();

const config = useRuntimeConfig();
if (process.client) {
    const hasToken = useCookie("XSRF-TOKEN");
    if (!hasToken.value) {
        console.log("no token");
        getSessionCookie();
    }
}

async function getSessionCookie() {
    console.log("getting session cookie from blank layout");
    const { data } = await axios.get(`${config.public.STATAMIC_HOST}/sanctum/csrf-cookie`);

    return data;
}

console.log("blank layout");
</script>

<template>
    <div>
        <main id="main-content" class="relative mx-auto min-h-screen max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
            <slot />
        </main>
    </div>
</template>
faul

<style scoped></style>
